import {
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { ArrowBack, SystemUpdateAlt } from '@material-ui/icons';
// import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Layout from '../../../components/Layout';
import SecondaryLayout from '../../../components/SecondaryLayout';
import TextWithDivider from '../../../components/TextWithDivider';
import { Paths } from '../../../routes/paths';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import { colors } from '../../../theme/colors';
import UploadedFileCard from '../components/UploadedFileCard';
import useDisplayImage from '../../../hooks/useDisplayImage';
import { uploadFile } from '../../../operations/misc';
import Message from '../../../components/Message';
import {
  createEnterprise,
  updateEnterprise,
} from '../../../helper/backendHelper/enterprise';
import { fileUpload } from '../../../helper/backendHelper/fileupload';
import { createPoc } from '../../../helper/backendHelper/poc';
import { useLocation, useParams } from 'react-router-dom';
// import CurrencyList from '../../../assets/currencyList';
import { Autocomplete } from '@mui/material';
import { CurrencyList } from '../../../assets/utils/currencyList';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { EmployeeCount } from '../../../assets/utils/employeeCount';
import { IndustriesList } from '../../../assets/utils/industriesList';

const useStyles = makeStyles((theme) => ({
  card: {
    border: `solid 1px ${colors.primary.main}`,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  uploadCard: {
    padding: 36,
    borderRadius: 16,
    width: '60%',
    height: '50%',
  },
}));

const CreateOrganization = () => {
  const updateData = useLocation()?.state?.state;
  const { id } = useParams();

  const history = useHistory();
  const [form, setForm] = useState({
    name: '',
    yearOfIncorporation: '',
    location: '',
    logoUrl: '',
    email: '',
    limitOfCollectionEvent: '',
  });
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { result, uploader } = useDisplayImage();

  // Currency state
  const [currencyValue, setCurrencyValue] = useState(null);
  const [currencyInputValue, setCurrencyInputValue] = useState('');

  // Industries state
  const [industriesValue, setIndustriesValue] = useState(null);
  const [industriesInputValue, setIndustriesInputValue] = useState('');

  //headCount state
  const [headCount, setHeadCount] = useState('');

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    if (name === 'yearOfIncorporation' && isNaN(value)) return;

    setForm({
      ...form,
      [name]: value,
    });
  };

  useEffect(() => {
    if (id) {
      setCurrencyValue(
        CurrencyList.find((x) => x.name === updateData?.currency?.name) || null
      );
      setIndustriesValue(
        IndustriesList.find((x) => x.label === updateData?.industry) || null
      );
      setHeadCount(id ? updateData?.headCount || null : null);
      setForm(updateData);
    }
  }, []);

  const isValid = () => {
    const { name, yearOfIncorporation, location, logoUrl, email } = form;
    return (
      name === '' ||
      yearOfIncorporation === '' ||
      logoUrl === '' ||
      location === '' ||
      email === ''
    );
  };

  const handleSubmit = async () => {
    if (form.yearOfIncorporation > new Date().getFullYear()) {
      Message.error(
        'Year of Incorporation cannot be greater than current year.'
      );
      return;
    }
    try {
      setLoading(true);
      const req = {
        ...form,
        headCount,
        currency: { name: currencyValue.name, id: currencyValue.cc },
        industry: industriesValue.label,
      };
      if (id) {
        const update = await updateEnterprise(id, req);
        Message.success(update.message || 'Updated Successfully');
      } else {
        const response = await createEnterprise(req);
        const pocObj = {
          name: 'poc',
          email: form.email,
          enterpriseId: response?.payload?._id,
          role: 'poc',
        };
        await createPoc(pocObj);
        Message.success(response.message);
      }
      history.push(Paths.adminOrganisation);
    } catch (error) {
      setLoading(false);
      Message.error(error?.response?.data?.message);
    }
  };

  //file uploading
  const handleImage = async (e) => {
    try {
      setLoading(true);
      let formData = new FormData();
      formData.append('file', e.target.files[0]);
      const response = await fileUpload(formData);
      setForm({
        ...form,
        logoUrl: response?.data?.payload?.url,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Message.error(error?.response?.data?.message);
    }
  };

  return (
    <Layout loading={loading}>
      <SecondaryLayout>
        <div className="ml-4">
          <Grid container>
            <Grid
              className="px-3"
              container
              alignItems="flex-start"
              direction="column"
              item
              xs={8}>
              <Grid
                style={{ height: 80 }}
                container
                justifyContent="space-between"
                className="py-4">
                <Grid container alignItems="center" item xs={6}>
                  <IconButton
                    onClick={() => {
                      history.push(Paths.adminOrganisation);
                    }}>
                    <ArrowBack />
                  </IconButton>
                  <Grid>
                    <TextWithDivider>
                      <Typography variant="h3" color="textPrimary">
                        Organisation
                      </Typography>
                    </TextWithDivider>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className="py-5 pl-4 w-100">
                <Grid spacing={2} container justifyContent="space-between">
                  <Grid container direction="column" item xs={4}>
                    <Typography variant="h3">Organisation Details</Typography>

                    <TextField
                      className="my-2"
                      value={form.name}
                      name="name"
                      placeholder="Organisation Name"
                      label="Organisation Name"
                      onChange={handleFormChange}
                    />

                    <Autocomplete
                      className="my-2"
                      value={currencyValue}
                      onChange={(event, newValue) => {
                        setCurrencyValue(newValue);
                      }}
                      inputValue={currencyInputValue}
                      onInputChange={(event, newInputValue) => {
                        setCurrencyInputValue(newInputValue);
                      }}
                      options={CurrencyList}
                      getOptionLabel={(option) =>
                        `${option.name} (${option.cc})`
                      }
                      renderOption={(props, option) => (
                        <li key={option.cc + option.name} {...props}>
                          {option.name} ({option.cc})
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Currency"
                          variant="standard"
                        />
                      )}
                    />

                    <FormControl variant="standard" className="my-2" fullWidth>
                      <InputLabel>Employee Headcount</InputLabel>
                      <Select
                        value={headCount}
                        name="headCount"
                        onChange={(e) => setHeadCount(e.target.value)}
                        placeholder="Employee Headcount">
                        {EmployeeCount.map((x, index) => {
                          return (
                            <MenuItem key={index} value={x.label}>
                              {`${x.label} employees`}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>

                    <Autocomplete
                      className="my-2"
                      value={industriesValue}
                      onChange={(event, newValue) => {
                        setIndustriesValue(newValue);
                      }}
                      inputValue={industriesInputValue}
                      onInputChange={(event, newInputValue) => {
                        setIndustriesInputValue(newInputValue);
                      }}
                      options={IndustriesList}
                      getOptionLabel={(option) => `${option.label}`}
                      renderOption={(props, option) => (
                        <li key={option.label} {...props}>
                          {option.label}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          
                          label="Select Industry"
                          placeholder="Select Industry"
                          variant="standard"
                        />
                      )}
                    />

                    <TextField
                      className="my-2"
                      value={form.yearOfIncorporation}
                      name="yearOfIncorporation"
                      placeholder="Year of Incorporation"
                      label="Year of Incorporation"
                      onChange={handleFormChange}
                      inputProps={{
                        maxLength: 4,
                      }}
                      InputProps={{
                        endAdornment: <CalendarTodayIcon />,
                      }}
                    />

                    <TextField
                      className="my-2"
                      value={form.location}
                      name="location"
                      placeholder="Location"
                      label="Location"
                      onChange={handleFormChange}
                      InputProps={{
                        endAdornment: <LocationOnOutlinedIcon />,
                      }}
                    />
                    <TextField
                      required
                      type="number"
                      className="my-2"
                      label="Limit of Collection event"
                      value={form.limitOfCollectionEvent}
                      name="limitOfCollectionEvent"
                      placeholder="Enter Limit of Collection event"
                      onChange={handleFormChange}
                    />

                    <TextField
                      className="my-2"
                      value={form.email}
                      required
                      disabled={id}
                      label="SPOC mail address"
                      fullWidth
                      placeholder="Enter SPOC mail address"
                      helperText="Add more than one ID by using the ; to seperate them"
                      name="email"
                      onChange={handleFormChange}
                    />

                    {/* {loading ? (
                      <Grid
                        className="mt-5"
                        container
                        justifyContent="center"
                        alignItems="center">
                        <CircularProgress size={24} />
                      </Grid>
                    ) : ( */}
                    <Button
                      className="mt-5"
                      variant="contained"
                      disableElevation
                      disabled={isValid()}
                      onClick={handleSubmit}
                      color="primary">
                      {id ? 'Update' : 'Create'} Organisation
                    </Button>
                    {/* )} */}
                  </Grid>
                  <Grid container justifyContent="center" item xs={8}>
                    <label htmlFor="upload-logo">
                      <Card
                        classes={{ root: classes.card }}
                        className={classes.uploadCard}
                        variant="outlined">
                        <Grid
                          style={{ width: '100%' }}
                          container
                          justifyContent="center"
                          alignItems="center">
                          <SystemUpdateAlt
                            style={{
                              transform: 'rotate(180deg)',
                              marginRight: 16,
                            }}
                          />
                          <Typography align="center" variant="h6">
                            Upload Company Logo here
                          </Typography>
                        </Grid>
                        <div style={{ marginTop: 32 }}>
                          <Typography
                            style={{ color: colors.grey[200] }}
                            variant="subtitle2">
                            Please upload on in the following file formats
                          </Typography>
                          <Typography
                            style={{ color: colors.grey[200] }}
                            variant="subtitle2">
                            .xls, .png, .jpg, .jpeg, .pdf and file size maximum
                            of 2MB
                          </Typography>
                        </div>
                      </Card>
                    </label>
                    <input
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="upload-logo"
                      onChange={handleImage}
                      type="file"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={4}>
              {form.logoUrl && (
                <Paper
                  style={{ width: '100%', height: 'calc(100vh - 56px)' }}
                  elevation={0}>
                  <Typography className="py-2 pl-2" variant="h3">
                    Uploaded Files
                  </Typography>
                  <hr />
                  <div className="m-3">
                    <UploadedFileCard
                      onDelete={() => {
                        setForm({
                          ...form,
                          logoUrl: '',
                        });
                      }}
                      result={form.logoUrl}
                    />
                  </div>
                </Paper>
              )}
            </Grid>
          </Grid>
        </div>
      </SecondaryLayout>
    </Layout>
  );
};

export default CreateOrganization;
